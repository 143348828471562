import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { captureMessage } from '@sentry/browser';
// import { toggleNoSleep } from './useWakeLock';

const env = process.env.REACT_APP_ENV;

const websocketURL = (spaceId: string, pin: string) => {
  if (env === 'production') {
    return `wss://x1mdlukes2.execute-api.eu-central-1.amazonaws.com/production?spaceId=${spaceId}&pin=${pin}`;
  } else if (env === 'staging') {
    return `wss://mexmvlybv5.execute-api.ap-southeast-2.amazonaws.com/dev?spaceId=${spaceId}&pin=${pin}`;    
  } else {
    //return `wss://3qk9cpokx2.execute-api.ap-southeast-2.amazonaws.com/dev?spaceId=${spaceId}&pin=${pin}`;
    return `wss://x1mdlukes2.execute-api.eu-central-1.amazonaws.com/production?spaceId=${spaceId}&pin=${pin}`;
  }
};
const useWebsocket = (setIsSubmitting: (isSubmitting: boolean) => void) => {
  const { t } = useTranslation();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [wsError, setWsError] = useState<string | null>(null);
  const [lastMessageData, setLastMessageData] = useState<any>({} as any);

  const connectToWebsocket = async ({
    spaceId,
    pin,
  }: {
    spaceId: string;
    pin: string;
  }) => {
    try {
      const wsURL = websocketURL(spaceId, pin);
      const client = new WebSocket(wsURL);
      setWs(client);
    } catch (err) {
      setWsError(err);
      throw new Error(err);
    }
  };

  useEffect(() => {
    console.log('ws: ', ws);

    if (ws) {
      ws.onopen = () => {
        ws.send('get session id');

        ws.send('{"message": "requirePin"}');

        setInterval(() => {
          ws.send('ping');
        }, 9 * 60000);

        setInterval(() => {
          ws.send('{"message": "requirePin"}');
        }, 110 * 60000);        
      };

      ws.onerror = (err: any) => {
        console.log('ws error: ', err);
        //captureMessage(err);
        setWsError(t('form.error_message'));
        setIsSubmitting(false);        
      };

      ws.onmessage = (message: MessageEvent) => {
        const data: any = JSON.parse(message.data);
        setLastMessageData(data);
      };

      ws.onclose = (event: CloseEvent) => {
        console.log('== CLOSING WS ==');
        console.log(event);
      };

      return () => {
        ws.close();
      };
    }
  }, [ws]);

  return {
    ws,
    setWs,
    wsError,
    lastMessageData,
    connectToWebsocket,
  };
};

export default useWebsocket;
