//@ts-nocheck
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/page_door_display.json';
import de from './locales/de/page_door_display.json';
import fr from './locales/fr/page_door_display.json';

let language;
if (window.navigator.languages) {
  language = window.navigator.languages[0];
} else {
  language = window.navigator.userLanguage || window.navigator.language;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      }
    },
    lng: language,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
