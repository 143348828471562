import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

const useOffline = (onlineCb: () => void, offlineCb: () => void) => {
  const [isOffline, setIsOffline] = useState<boolean>(!navigator.onLine);
  const { addToast, removeAllToasts } = useToasts();
  // useEffect(() => {
  //   isOffline
  //     ? addToast('Internet connection lost. Please reconnect', {
  //         appearance: 'error',
  //         autoDismiss: false,
  //       })
  //     : removeAllToasts();
  // }, [isOffline]);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setIsOffline(true);
      offlineCb();
    });
    window.addEventListener('online', () => {
      setIsOffline(false);
      onlineCb();
    });
    return () => {
      window.removeEventListener('offline', () => setIsOffline(true));
    };
  }, []);

  return {
    isOffline,
  };
};

export default useOffline;
