import { useEffect } from 'react';
import NoSleep from 'nosleep.js';
import { useToasts } from 'react-toast-notifications';

const useWakeLock = () => {
  const { addToast } = useToasts();
  useEffect(() => {
    console.log('useWakeLock');

    if ('wakeLock' in navigator) {
      console.log('Wakelock found', navigator);

      let wakeLock: any = null;

      const requestWakeLock = async () => {
        try {
          wakeLock = await (navigator as NavigatorExtended).wakeLock.request(
            'screen',
          );
          wakeLock.addEventListener('release', () => {
            // console.log('Screen Wake Lock was released');
          });
        } catch (err) {
          console.error(`${err.name}, ${err.message}`);
        }
      };
      requestWakeLock();
      const handleVisibilityChange = () => {
        if (wakeLock !== null && document.visibilityState === 'visible') {
          requestWakeLock();
        }
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);
      console.log(wakeLock);
    } else {
      const noSleep = new NoSleep();

      let wakeLockEnabled = false;
      const submitButton = document.getElementById('submitButton');
      submitButton?.addEventListener(
        'click',
        function() {
          if (!wakeLockEnabled) {
            noSleep.enable(); // keep the screen on!
            // addToast('Wake Lock On', { appearance: 'success' });
            wakeLockEnabled = true;
          } else {
            noSleep.disable(); // let the screen turn off.
            // addToast('Wake Lock Off', { appearance: 'error' });
            wakeLockEnabled = false;
          }
        },
        false,
      );
    }
  }, []);
};

export const toggleNoSleep = (keepScreenAwake: boolean) => {
  const noSleep = new NoSleep();
  keepScreenAwake ? noSleep.enable() : noSleep.disable();
}

export default useWakeLock;
