export const checkPlatform = {
  isIos: () =>
    ['iPad Simulator', 'iPad'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
  isSafari: () =>
    ['Safari'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Safari') && 'ontouchend' in document),
  isAndroid: () =>
    ['Android'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Android') && 'ontouchend' in document),
  isChrome: () =>
    ['Chrome'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Chrome') && 'ontouchend' in document),
};

export const getPlatform = () => {
  if (
    ['iPad Simulator', 'iPad'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'IOS';
  }
  if (
    ['Safari'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Safari') && 'ontouchend' in document)
  ) {
    return 'SAFARI';
  }
  if (
    ['Android'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Android') && 'ontouchend' in document)
  ) {
    return 'ANDROID';
  }
  if (
    ['Chrome'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Chrome') && 'ontouchend' in document)
  ) {
    return 'CHROME';
  }
};
