import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { DisplayContainer } from '../doorDisplayStyle';

import { ReactComponent as IndicatorIcon } from 'assets/indicator.svg';
import { Counts } from '../models/DisplayStatus';
import { Paragraph as P, IcomoonIcons, Grid, Flex } from 'components';
import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';
import { theme } from 'style';

interface Status {
  message: string;
  icon: IcomoonIconNames;
  color: string;
}
interface StatusValues {
  Within: Status;
  Exceeded: Status;
  Approaching: Status;
  Offline: Status;
  NotCommissioned: Status;
}

const values: StatusValues = {
  Within: {
    message: 'display_message.within',
    icon: 'success',
    color: 'success',
  },
  Exceeded: {
    message: 'display_message.exceed',
    icon: 'error',
    color: 'error',
  },
  Approaching: {
    message: 'display_message.within',
    icon: 'success',
    color: 'success',
  },
  Offline: {
    message: 'display_message.offline',
    icon: 'offline',
    color: 'grey',
  },
  NotCommissioned: {
    message: 'display_message.offline',
    icon: 'offline',
    color: 'grey',
  },
};

const Connect = ({
  counts,
  displayName,
  entranceDisplayMessage,
}: {
  counts: Counts;
  displayName: string;
  entranceDisplayMessage: string;
}) => {
  const { t } = useTranslation();
  const { currentOccupancy, maxOccupancy, isOffline } = counts;
  const percent = Math.round((currentOccupancy * 100) / maxOccupancy);

  const status = percent >= 100 ? 'Exceeded' : 'Within';

  const { message, icon, color }: any = values[isOffline ? 'Offline' : status];
  return (
    <Grid
      gridTemplateRows="auto 150px"
      style={{
        padding: '30px 30px  0 30px',
        width: '100%',
        height: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: theme.colors[color],
          borderRadius: 4,
          padding: 20,
          height: '100%',
        }}>
        <div style={{ padding: 0, width: '90%', textAlign: 'center' }}>
          <h3 style={{ color: 'white' }}>{entranceDisplayMessage}</h3>
          {/* <p style={{ color: 'white', fontSize: 22, fontWeight: 'bold' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor
          </p> */}
        </div>

        <DisplayContainer>
          <IcomoonIcons icon={icon} color={'white'} size={200} />
        </DisplayContainer>
        <h1 style={{ color: 'white', margin: 0 }}>{t(message.toString())}</h1>
      </div>

      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'left',
          flexDirection: 'column',
        }}>
        <CountScale percentage={percent} />
        <Flex marginTop={40}>
          <P size="small" color="primary" textAlign="left" bold>
            {t('display.title')}
          </P>
          <P size="small" color="primary" textAlign="left" paddingLeft={'5px'}>
            {displayName}
          </P>
        </Flex>
      </div>
    </Grid>
  );
};

const CountScale = ({ percentage }: { percentage: number }) => {
  const Gradient = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(
      90deg,
      #5fa82a 56.91%,
      #ffbb00 83.81%,
      #e4002b 100%
    );

    > span {
      position: absolute;
      color: ${theme.colors.charcoal};
      font-weight: 500;
      top: -30px;
    }
  `;

  const Bookend = styled.span`
    color: ${theme.colors.charcoal};
    font-weight: 500;
    position: absolute;
    top: -30px;
  `;

  const percentConstraint = (percent: number) => {
    if (percent < 0) return 0;
    if (percent > 100) return 100;
    return percent;
  };
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        marginTop: 40,
      }}>
      {percentage >= 5 && <Bookend style={{ left: 0 }}>0%</Bookend>}
      <Gradient>
        <span
          style={{
            color: theme.colors.primary,
            left: `${percentConstraint(percentage)}%`,
            transform: 'translateX(-50%)',
          }}>
          {percentConstraint(percentage)}%
        </span>

        <IndicatorIcon
          fill={theme.colors.primary}
          style={{
            position: 'absolute',
            bottom: '-50%',
            left: `${percentConstraint(percentage)}%`,
            transform: `translateX(-50%)`,
          }}
        />
      </Gradient>

      {percentage <= 94 && <Bookend style={{ right: 0 }}>100%</Bookend>}
    </div>
  );
};

export default Connect;
