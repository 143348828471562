import styled from '@emotion/styled';

import { Props } from './Grid';
import { grid, flexbox, layout, space } from 'styled-system';

export const StyledGrid = styled.div<Props>`
  display: grid;
  ${grid}
  ${flexbox}
  ${layout}
  ${({ vertical }) =>
    vertical &&
    `
    grid-auto-flow: row;
  `}
  ${({ horizontal }) =>
    horizontal &&
    `
    grid-auto-flow: column;
  `}
  ${space}
`;
