import React from 'react';

import {
  TextAlignProps,
  FontWeightProps,
  SpaceProps,
  FontSizeProps,
  GridRowProps,
  GridColumnProps,
  ColorStyleProps,
} from 'styled-system';

import { StyledParagraph } from './paragraphStyle';

export type ParagraphSizes = 'large' | 'medium' | 'small' | 'normal' | 'tiny';
export interface HeadingProps
  extends TextAlignProps,
    GridRowProps,
    GridColumnProps,
    FontWeightProps,
    SpaceProps,
    ColorStyleProps,
    React.HTMLAttributes<HTMLParagraphElement>,
    FontSizeProps {
  /** The pre-determined size of the text */
  size?: ParagraphSizes;
  /** Make the text bold */
  bold?: boolean;
  /** Text align center */
  center?: boolean;
  /** Text is uppercase */
  uppercase?: boolean;
  /** To render the component as another one */
  as?: React.ReactNode;
}

/**
 * Component to render any Heading text found throught the app
 * Sizes are in sync with the Figma design system. Any updates to sizes and colours are to be down in paragraphStyle
 * Strings are parsed for html for extra control
 */

const Paragraph: React.FC<HeadingProps> = ({
  children,
  color,
  ...rest
}: HeadingProps) => {
  return (
    <StyledParagraph color={color} {...rest}>
      {children}
    </StyledParagraph>
  );
};

export default Paragraph;
