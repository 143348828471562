import React from 'react';
import { Badge, EntriWorX, Insights } from './homeLogoStyle';

interface Props
  extends 
  React.HTMLAttributes<HTMLHeadingElement> {  
  productName: string;
  variant: 'primary' | 'secondary'
}

const HomeLogo: React.FC<Props> = ({ productName, variant = 'primary', children, color, ...rest }) => {
  return (
    <div>
      <EntriWorX variant={variant}>EntriWorX</EntriWorX>
      <Insights variant={variant}>Insights</Insights>
      {productName.length > 0 ? <Badge variant={variant}> {productName}</Badge> : <></>}      
    </div>
  );
};

export default HomeLogo;