import React from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

export const flexCenterAll = () =>
  `
    display: flex;
    justify-content: center;
    align-items: center;
  `;

export const theme: { [key: string]: any } = {
  colors: {
    primary: "#003594",
    secondary: "#E4002B",
    black: "#000000",
    grey: "#A1A1A1",
    greyBlue: "#6789C6",
    charcoal: "#424242",
    darkBlue: "#052052",
    lightGreyBlue: "#F5F5F7",
    lightBlueGrey: "#D7E1F1",
    blueGrey: "#99A8B8",
    lightGrey: "#E3E3E3",
    success: "#5FA82A",
    pending: "#FFDD00",
    error: "#E4002B",
    warning: "#FFBB00",
    white: "#FFFFFF",
    gradients: {
      primary: `linear-gradient(0deg, #FCFDFF 0%, #F8F9FF 100%);`,
      alert:
        "linear-gradient(180deg, rgba(252, 253, 255, 0.0001) -80.71%, #FAEBEF 100%);",
    },
    alerts: {
      obstruction: "#5FA72A",
      lowBattery: "#003594",
      mainsPower: "#F39201",
      presenceSensor: "#4FAEAD",
      fireAlarmOn: "#FFDE02",
      internalActuator: "#C800B8",
      externalActuator: "#5FA72A",
      generalFault: "#E4002B",
    },
    withAlerts: "#ff2828",
    withoutAlerts: "#35e05e",
  },

  typography: {
    fontFamily: `Poppins, sans-serif`,
    fontWeight: {
      body: "normal",
      heading: "bold",
    },
  },
  vars: {
    headerHeight: "70px",
  },
  borders: (width: any, color: any) =>
    color !== "transparent"
      ? `${width}px solid ${theme.colors[color]}`
      : `${width}px solid transparent`,
  boxShadow: "0px 2px 24px rgba(0,0,0,0.0507246)",
} as const;
export const inputDefaults = {
  height: 50,
  paddingLeft: 15,
  border: `1px solid ${theme.colors.blueBlueGrey}`,
  marginBottom: 11,
};

export const InputContainer = styled.div`
  width: 100%;
  &:first-of-type {
    margin-bottom: 20px;
  }
  
`;

export const InputLabel = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: #003594;
  margin-bottom: 10px;
`;

export const InputError = styled.div`
  height: 30px;
  margin-top: 8px;
  margin-bottom: 20px;
  p {
    color: #e4002b;
  }
`;

interface InputProps {
  disabled?: boolean;
  error?: boolean;
}

export const StyledInput = styled.input<InputProps>`
  ${inputDefaults};
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0;
  line-height: normal;
  font-size: 14px;
  &:focus {
    border-color: ${theme.colors.primary};
    outline: none;
  }
  color: ${theme.colors.charcoal};
  ::placeholder {
    color: ${theme.colors.blueGrey};
  }
  background-color: ${(props) =>
  props.disabled ? theme.colors.lightGrey : `${theme.colors.white} !important`};
  border: ${(props) =>
  props.disabled
    ? `1px solid ${theme.colors.lightGrey}`
    : theme.borders(1, "lightBlueGrey")};
  ${({ error }) =>
  error &&
  `
      border: ${theme.borders(1, "secondary")}
    `};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${theme.colors.charcoal};
    box-shadow: 0 0 0 30px white inset !important;
    color: ${theme.colors.charcoal} !important;
  }
`;

export const InputFieldWrapper = styled.div`
  position: relative;
`;
