import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Paragraph as P,
  SmallFormContainer,
  Button,
  Flex,
  Spinner,
} from 'components';

import {
  InputContainer,
  InputLabel,
  InputError,
  InputFieldWrapper,
  StyledInput,
} from 'style';

interface Props {
  error: string | null;
  isSubmitting: boolean;
  connectToWebsocket: () => void;
  inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  cancelConnect: () => void;
  spaceId: string;
  accessCode: string;
}

const Connect = ({
  connectToWebsocket,
  isSubmitting,
  error,
  inputHandler,
  cancelConnect,
  spaceId,
  accessCode,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {isSubmitting ? (
        <>
          <Spinner color="primary" />
          <Button onClick={cancelConnect}>Cancel</Button>
        </>
      ) : (
        <SmallFormContainer
          mainHeading={t('form.main_heading')}
          subHeading={t('form.sub_heading')}>
          <InputContainer>
            <Flex>
              <InputLabel>{t('form.space_id')}</InputLabel>
              {/* <InputLabel>{t('form.field_label')}</InputLabel> */}
            </Flex>
            <InputFieldWrapper>
              <StyledInput
                type={'text'}
                name="spaceId"
                value={spaceId}
                onChange={inputHandler}
              />
            </InputFieldWrapper>
          </InputContainer>
          <InputContainer>
            <Flex>
              <InputLabel>{t('form.access_code')}</InputLabel>
            </Flex>
            <InputFieldWrapper>
              <StyledInput
                id="submitButton"
                type={'text'}
                name="accessCode"
                value={accessCode}
                onChange={inputHandler}
              />
            </InputFieldWrapper>
            <InputError>
              {error && (
                <P size="small" color="secondary">
                  {error}
                </P>
              )}
            </InputError>
          </InputContainer>
          <Button onClick={connectToWebsocket}>{t('form.button')}</Button>
        </SmallFormContainer>
      )}
    </>
  );
};

export default Connect;
