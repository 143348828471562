import styled from '@emotion/styled';
import { theme } from 'style';

export const ToastWrapper = styled.span`
  position: absolute;
  display: flex;

  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  height: auto;
  width: auto;
  padding: 15px;
  z-index: 11;
  background-color: #ffebe6;
  border: 2px solid ${theme.colors.error};

  > p {
    color: ${theme.colors.error};
    margin: 0;
    font-size: 1.4rem;
    margin-left: 15px;
  }
`;
