import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastWrapper } from './toastStyle';

import { IcomoonIcons } from 'components';

interface Props {}

const Toast = ({}: Props) => {
  const { t } = useTranslation();
  return (
    <ToastWrapper>
      <IcomoonIcons icon="error" color="error" />
      <p>{t('offline.toast')}</p>
    </ToastWrapper>
  );
};

export default Toast;
