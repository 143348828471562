import styled from "@emotion/styled";

import { FlexProps } from "./Flex";
import { flexbox, layout, space } from "styled-system";

export const StyledFlex = styled.div<FlexProps>`
  display: flex;
  position: relative;
  width: ${(props) => (props.stretch ? "100%" : "auto")};
  ${({ centered }) =>
  centered &&
  `
    align-items: center;
    justify-content: center;
  `};
  ${flexbox}
  ${layout}
  ${space}
`;
