import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { StyledModal, StyledBody, Backdrop, Wrapper } from './style';
import { Button, Flex, Spinner } from 'components';

import IcomoonIcons from 'components/IcomoonIcons/IcomoonIcons';
import { theme } from 'style';
import { borderRadius } from 'styled-system';

export type States =
  | 'ios'
  | 'IOS'
  | 'android'
  | 'ANDROID'
  | 'safari-fallback'
  | 'installing'
  | 'app-installed'
  | boolean;
interface Props {
  isOpen: States;
  toggle: () => void;
  // androidPrompt: any;
}

const HowToInstall = ({ isOpen, toggle }: Props) => {
  const { t } = useTranslation();

  const modal = (
    <>
      <Backdrop />
      <Wrapper id="wrapper" platform={isOpen}>
        {(isOpen === 'ios' || isOpen === 'IOS') && (
          <>
            <StyledModal>
              <div className="pointer" />
              <StyledBody>
                <h5>{t('how_to_install.heading')}</h5>
                <p>{t('how_to_install.ios.text')}</p>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  mb="10px"
                  mt="15px">
                  <IcomoonIcons icon="share" color="primary" size={24} />
                  <p>{t('how_to_install.ios.step_one')}</p>
                </Flex>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  mb="15px">
                  <div
                    style={{
                      border: `2px solid ${theme.colors.primary}`,
                      borderRadius: 4,
                      margin: 1,
                      padding: 1,
                    }}>
                    <IcomoonIcons icon="add" color="primary" size={20} />
                  </div>

                  <p>{t('how_to_install.ios.step_two')}</p>
                </Flex>
                <Flex justifyContent="center" mb="15px">
                  <Button onClick={toggle}>
                    {t('how_to_install.ios.close')}
                  </Button>
                </Flex>
                <p style={{ fontSize: 12, margin: 0, lineHeight: 1.2 }}>
                  * Can only be installed with Safari. If opened in another
                  browser, please copy the link and re-open in Safari.
                </p>
              </StyledBody>
            </StyledModal>
          </>
        )}
        {isOpen === 'safari-fallback' && (
          <StyledModal>
            <StyledBody>
              <p>{t('how_to_install.ios.fallback')}</p>
              <Flex justifyContent="center">
                <Button onClick={toggle}>
                  {t('how_to_install.ios.close')}
                </Button>
              </Flex>
            </StyledBody>
          </StyledModal>
        )}
        {(isOpen === 'android' || isOpen === 'ANDROID') && (
          <StyledModal>
            <span
              onClick={toggle}
              style={{
                position: 'absolute',
                top: 25,
                right: 25,
              }}>
              <IcomoonIcons icon="close" />
            </span>

            <StyledBody>
              <h5>{t('how_to_install.heading')}</h5>
              <p>{t('how_to_install.android.text')}</p>

              <Flex justifyContent="center" mt="15px">
                <Button data-install="android-install">
                  {t('how_to_install.android.add')}
                </Button>
              </Flex>
            </StyledBody>
          </StyledModal>
        )}
        {isOpen === 'installing' && (
          <StyledModal>
            <StyledBody>
              <Flex justifyContent="center" alignItems="center">
                <Spinner />
                <h5>{'Installing'}</h5>
              </Flex>
            </StyledBody>
          </StyledModal>
        )}
        {isOpen === 'app-installed' && (
          <StyledModal>
            <StyledBody>
              <Flex justifyContent="center" alignItems="center">
                <p>
                  App is installed. Close the browser and open it with the icon
                </p>
              </Flex>
            </StyledBody>
          </StyledModal>
        )}
      </Wrapper>
    </>
  );

  return isOpen ? ReactDOM.createPortal(modal, document.body) : null;
};

export default HowToInstall;
