import axios from 'axios';
import { DisplayStatus } from '../models/DisplayStatus';
const env = process.env.REACT_APP_ENV;

const URL: { [key: string]: string } = {
  development:
    //'https://b6urvloe5d.execute-api.ap-southeast-2.amazonaws.com/Prod/api/',
    'https://w9mgy1lwa5.execute-api.eu-central-1.amazonaws.com/Prod/api/',
  staging:
    'https://obau4i5fi9.execute-api.ap-southeast-2.amazonaws.com/Prod/api/',
  production:
    'https://w9mgy1lwa5.execute-api.eu-central-1.amazonaws.com/Prod/api/',
};
const instance = axios.create({
  baseURL: URL[env || 'development'],
});

export const fetchExternalDisplayStatus = async (sessionId: string) => {
  try {
    const request = await instance.get(
      `ExternalDisplay/details?sessionId=${sessionId}`,
    );
    const { status } = request;
    const data: DisplayStatus = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};
