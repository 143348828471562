import styled from '@emotion/styled';
import { flexCenterAll, theme } from 'style';
import { Paragraph } from 'components';

export const Wrapper = styled.div`
  ${flexCenterAll}
  padding: 55px;
  flex-direction: column;
  background-color: white;
  width: 460px;
  border-radius: 4px;
  box-shadow: ${theme.boxShadow};
`;

export const Heading = styled.div`
  text-align: center;
  ${flexCenterAll}
  flex-direction: column;
  p:first-of-type {
    margin-bottom: 6px;
  }
`;

export const Form = styled.div`
  width: 100%;
  button {
    width: 100%;
  }
`;

export const Subheading = styled(Paragraph)`
  text-align: center;
  font-size: 16px;
  margin-bottom: 24px;
  color: ${theme.colors.charcoal};
`;
