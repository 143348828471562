import styled from '@emotion/styled';
import { platform } from 'os';
import { theme } from 'style';
import { States } from './HowToInstall';

const DESKTOP_PADDING = 'padding: 30px 45px;';
const MOBILE_PADDING = 'padding: 20px 25px;';

export const StyledModal = styled.div`
  position: relative;
  box-sizing: border-box;
  /* min-width: 500px; */
  max-width: 545px;
  margin: 0 auto;
  background-color: white;
  box-shadow: ${theme.boxShadow};
  border-radius: 4px;

  .pointer {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: white;
    top: -10px;
    right: 120px;
    transform: rotate(45deg);
  }
`;

export const StyledHeader = styled.div`
  ${DESKTOP_PADDING};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${theme.borders(1, 'lightBlueGrey')};
`;

export const StyledActions = styled.div`
  ${DESKTOP_PADDING};
  height: auto;
  border-top: ${theme.borders(1, 'lightBlueGrey')};
`;

export const StyledBody = styled.div`
  text-align: center;
  ${DESKTOP_PADDING};
  h5 {
    color: ${theme.colors.primary};
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin: 0;
  }
  p {
    margin: 0;
    padding: 0;
    margin-left: 10px;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.charcoal};
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`;

const positionSwitch = (platform: States) => {
  switch (platform) {
    case 'ios':
      return `
        top: 20px;
        right: 20px;
      `;
    case 'android':
    case 'installing':
    case 'app-installed':
      return `
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        `;
    case 'safari-fallback':
      return `
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
            `;
    default:
      return ``;
  }
};
export const Wrapper = styled.div<{
  platform: States;
}>`
  ${(props) => positionSwitch(props.platform)}
  position: fixed;
  z-index: 700;
  width: inherit;
  outline: 0;
`;
