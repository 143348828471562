import styled from '@emotion/styled';
import { flexCenterAll } from 'style';

export const Wrapper = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 70px auto;
  overflow: hidden;

  > div {
    ${flexCenterAll};
    flex-direction: column;
  }
  > div:first-of-type {
    background-color: #ffffff;
    position: relative;
  }
`;

export const DisplayContainer = styled.div`
  /* background-color: #ffffff; */
  padding: 10px;
  height: auto;
  width: auto;
  ${flexCenterAll};
  flex-direction: column;
`;

export const EndSession = styled.div`
  outline: 1px solid red;
  z-index: 2;
  cursor: pointer;
  border: none;
  outline: none;
  ${flexCenterAll};
  background-color: #d7e1f1;
  font-size: 14px;
  color: #003594;
  height: 30px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  padding: 0px 10px;

  > span:first-of-type {
    margin-left: 5px;
  }
`;
