import React from 'react';

import { Wrapper, Form, Heading, Subheading } from './smallFormContainerStyle';


interface Props {
  mainHeading: string;
  subHeading?: string;
}

const SmallFormContainer: React.FC<Props> = ({
  children,
  mainHeading,
  subHeading,
}) => {
  return (
    <Wrapper data-cypress="small-form-container">
      <Heading>

        <h3>
  {mainHeading}
        </h3>
        {subHeading && <Subheading color="charcoal">{subHeading}</Subheading>}
      </Heading>

      <Form>{children}</Form>
    </Wrapper>
  );
};

export default SmallFormContainer;
