import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastProvider } from 'react-toast-notifications';

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import 'i18n/i18n';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn:
//       'https://3bdb7512b9544844ab30635bf7161a45@o479031.ingest.sentry.io/5528103',
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider placement="top-left">
      <App />
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    alert('New version available. Will update on close.');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});
