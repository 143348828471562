import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import { captureMessage } from '@sentry/browser';

import { Wrapper, EndSession } from './doorDisplayStyle';
import { ReactComponent as Logo } from './assets/OccupancyInsights.svg';
import { ReactComponent as DormaLogo } from './assets/Dormakaba.svg';
import { Connect, Display, SmallFormContainer } from './components';

import { IcomoonIcons, Flex, HowToInstall, Toast } from 'components';

import { StatusEnum, Counts } from './models/DisplayStatus';
import { fetchExternalDisplayStatus } from './service';
import { theme } from 'style';

import useWebsocket from './hooks/useWebsocket';
import useOffline from './hooks/useOffline';

import { checkPlatform, } from './utils/install';
import HomeLogo from 'components/HomeLogo/HomeLogo';
import { toggleNoSleep } from 'hooks/useWakeLock';

export interface SpaceInfo {
  displayName: string;
  displayMessage: string;
}
function App() {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // this wasn't firing on the click event of the access code text box
  //useWakeLock();
  // const { showInstallMessage, setShowInstallMessage } = useInstallPrompt();

  const {
    ws,
    connectToWebsocket,
    lastMessageData,
    setWs,
    wsError,
  } = useWebsocket(setIsSubmitting);

  const params = new URLSearchParams(window.location.search);
  const paramIdentifier = params.get('identifier');
  const paramPin = params.get('pin');

  const [spaceId, setSpaceId] = useState<string>(
    window.localStorage.getItem('spaceId') || '',
  );
  const [accessCode, setAccessCode] = useState<string>(
    window.localStorage.getItem('accessCode') || '',
  );

  const [sessionId, setSessionId] = useState<string>('');

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'spaceId') {
      setSpaceId(value);
    }
    if (name === 'accessCode') {
      setAccessCode(value);
    }
  };

  const [error, setError] = useState<string | null>(null);

  const [spaceInfo, setSpaceInfo] = useState<SpaceInfo>({} as SpaceInfo);
  const [spaceStatus, setSpaceStatus] = useState<Counts | null>(null);

  const fetchStatus = async (sessionId: string) => {
    try {
      const res = await fetchExternalDisplayStatus(sessionId);
      if (res) {
        setSpaceInfo({
          displayName: res.displayName,
          displayMessage: res.entranceDisplayMessage,
        });
        setSpaceStatus({
          maxOccupancy: res.maxOccupancy,
          approachingCount: res.approachingCount,
          currentOccupancy: res.currentOccupancy,
          isOffline: res.isOffline,
        });
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const endSession = () => {
    if (ws) {
      ws.close();
    }
    setSpaceStatus(null);
    setIsSubmitting(false);
    setError(null);
    setAccessCode('');
    window.localStorage.removeItem('accessCode');
    toggleNoSleep(false);
  };

  useEffect(() => {
    if (paramIdentifier) {
      setSpaceId(paramIdentifier);
    }
    if (paramPin) {
      setAccessCode(paramPin);
    }
    if (spaceId && accessCode) {
      setIsSubmitting(true);
      connectToWebsocket({ spaceId, pin: accessCode });
    }
    return () => {
      window.localStorage.removeItem('accessCode');
    };
  }, []);

  useEffect(() => {
    if (wsError) {
      setIsSubmitting(false);
      setError(wsError);
    }
  }, [wsError]);

  useEffect(() => {
    if (sessionId) {
      fetchStatus(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (lastMessageData) {
      //captureMessage(lastMessageData);
      if (lastMessageData.hasOwnProperty('sessionId')) {
        setSessionId(lastMessageData.sessionId);
      }

      if (lastMessageData.hasOwnProperty('spaceId')) {
        window.localStorage.setItem('accessCode', lastMessageData.newPin);
      }

      if (lastMessageData.hasOwnProperty('Event')) {
        if (lastMessageData.Event === 'OccupancyChange') {
          if (spaceStatus) {
            setSpaceStatus({
              ...spaceStatus,
              currentOccupancy: lastMessageData.Data.OccupancyCount,
            });
          }
        }
        if (lastMessageData.Event === 'SpaceCacheCleared') {
          fetchStatus(sessionId);
        }
      }
    }
  }, [lastMessageData]);

  const offlineCallback = () => {
    if (ws) {
      ws.close();
    }
    setWs(null);
  };
  const onlineCallback = () => {
    const pin = window.localStorage.getItem('accessCode');
    if (pin) {
      connectToWebsocket({ spaceId, pin });
    }
  };

  const { isOffline } = useOffline(onlineCallback, offlineCallback);

  const RECONNECT_TIME = 1000 * 60 * 110;
  useEffect(() => {
    setInterval(() => {
      const pin = window.localStorage.getItem('accessCode');
      const id = window.localStorage.getItem('spaceId');
      if (pin && id) {
        connectToWebsocket({ spaceId: id, pin });
      }
    }, RECONNECT_TIME); // Reconnect web socket after 110 mins to avoid the hard limit of 2 hours auto disconnection.
  }, []);

  /**
   * Install prompt running in root instead of from a hook, which is re-running after complete
   */

  const [showInstallMessage, setShowInstallMessage] = useState<any>(false);
  const [isAndroidFirstRun, setIsAndroidFirstRun] = useState<boolean>(true);
  const navigator: any = window.navigator;
  const iOSIsInstalled = navigator.standalone === true;

  useEffect(() => {
    if (checkPlatform.isIos() && checkPlatform.isSafari() && !iOSIsInstalled) {
      setShowInstallMessage('ios');
    }
    if (checkPlatform.isIos() && !checkPlatform.isSafari()) {
      setShowInstallMessage('safari-fallback');
    }
    if (checkPlatform.isAndroid()) {
      // let isAndroidFirstRun = true;
      let deferredPrompt: any;

      window.addEventListener('beforeinstallprompt', (e: any) => {
        console.log('DEBUG === beforeinstallprompt event listener');
        console.log(e);

        console.log(
          'DEBUG === beforeinstallprompt isAndroidFirstRun: ',
          isAndroidFirstRun,
        );
        e.preventDefault();

        deferredPrompt = e;
        if (isAndroidFirstRun) {
          setIsAndroidFirstRun(false);
          setShowInstallMessage('android');
          const btn = document.querySelector(
            '[data-install="android-install"]',
          );
          btn?.addEventListener('click', (e: any) => {
            console.log('DEBUG === Click event of in the prompt', e);
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: any) => {
              console.log('DEBUG === choiceResult', choiceResult);
              console.log(
                'DEBUG === choiceResult.outcome',
                choiceResult.outcome,
              );
              if (choiceResult.outcome === 'accepted') {
                setShowInstallMessage('installing');
              } else {
                console.log('User dismissed the install prompt');
              }
              deferredPrompt = null;
            });
          });
        } else {
          console.log('DEBUG === isAndroidFirstRun: ', isAndroidFirstRun);
          setShowInstallMessage('app-installed');
        }
      });
      window.addEventListener('appinstalled', (event: any) => {
        setShowInstallMessage('app-installed');
      });
    }
    if (checkPlatform.isAndroid() && !checkPlatform.isChrome()) {
      // fallback
    }
  }, [isAndroidFirstRun]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Wrapper id="app">
          {isOffline && <Toast />}
          {isOffline && spaceStatus && (
            <span
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                backgroundColor: 'black',
                opacity: 0.2,
                zIndex: 2,
              }}
            />
          )}
          <div>
            <HomeLogo variant='primary' productName='for Entrivo Occupancy Insights' />
            {spaceStatus && (
              <EndSession onClick={endSession}>
                <IcomoonIcons icon="log-out" size={12} color="primary" />
                <span>{t('end_session')}</span>
              </EndSession>
            )}
          </div>
          <Flex justifyContent="center" alignItems="center">
            {spaceStatus ? (
              <Display
                entranceDisplayMessage={spaceInfo.displayMessage}
                displayName={spaceInfo.displayName}
                counts={spaceStatus}
              />
            ) : isOffline ? (
              <SmallFormContainer
                mainHeading={t('offline.home.main')}
                subHeading={t('offline.home.sub')}
              />
            ) : (
              <Connect
                inputHandler={inputHandler}
                spaceId={spaceId}
                accessCode={accessCode}
                connectToWebsocket={() => {
                  window.localStorage.setItem('spaceId', spaceId);
                  window.localStorage.setItem('accessCode', accessCode);
                  setIsSubmitting(true);
                  connectToWebsocket({ spaceId, pin: accessCode });
                  toggleNoSleep(true);
                }}
                isSubmitting={isSubmitting}
                error={error}
                cancelConnect={() => {
                  endSession();
                  setIsSubmitting(false);
                }}
              />
            )}
          </Flex>

          <div style={{ position: 'absolute', bottom: 15, right: 35 }}>
            <DormaLogo />
          </div>
        </Wrapper>
      </ThemeProvider>
      <HowToInstall
        isOpen={showInstallMessage}
        toggle={() => setShowInstallMessage(false)}
      />
    </>
  );
}

export default App;
